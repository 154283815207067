
import * as ipxRuntime$EqUnr6IBAv from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_rollup@4.19.1/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxStaticRuntime$BgoU9F33NK from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_rollup@4.19.1/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$EqUnr6IBAv, defaults: {} },
  ['ipxStatic']: { provider: ipxStaticRuntime$BgoU9F33NK, defaults: {} }
}
        